import { Grid, Typography } from '@mui/material';
import currencyFormatter from 'currency-formatter';
import { endOfDay, parseISO } from 'date-fns';
import {
  HuiAutocomplete,
  HuiCalendar,
  HuiCard,
  HuiCardContent,
  HuiCheckbox,
  HuiInput,
  HuiPanel,
  HuiPanelActions,
  HuiPanelContent,
  HuiPanelHeader,
  HuiRadio,
  HuiRadioOption,
  HuiSelect,
} from 'handle-ui';
import HuiFileUpload from 'handle-ui/components/hui-file-upload/HuiFileUpload';
import { debounce, first, isEmpty, size, startCase, toLower } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import toastr from 'toastr';
import { getInvoicesToBeWaived } from '../../../actions/project/getInvoicesToBeWaived';
import { getProjectsFilter } from '../../../actions/project/getProjectsFilter';
import { createWaiverRequest } from '../../../actions/waiver/createWaiverRequest';
import GoogleAddressForm from '../../../components/google-maps/GoogleAddressForm';
import SidebarHeader from '../../../components/layout/sidebar/SidebarHeader';
import { getLocalStorageItem, setLocalStorageItem } from '../../../components/localStorage/HuiLocalStorage';
import { closeLoading, openLoading } from '../../../redux/LoadingSlice';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';
import { selectUser } from '../../../redux/UserSlice';
import { getDateInTimeZone } from '../../../utils/utilsDate';
import HistoryInvoices from '../history/HistoryInvoices';
import HistoryWaivers from '../history/HistoryWaivers';
import RequestWaiverPaymentDetail from './RequestWaiverPaymentDetail';
import { removeExtraSpaces } from '../../../utils/utilsTexts';

const defaultCalendar = {
  dateSelected: null,
  value: false,
};

export const localStoragePrefix = 'request-waiver';

const waiverTypeOptions = [
  {
    title: 'Conditional Progress',
    id: 'conditional.progress',
  },
  {
    title: 'Unconditional Progress',
    id: 'unconditional.progress',
  },
  {
    title: 'Conditional Final',
    id: 'conditional.final',
  },
  {
    title: 'Unconditional Final',
    id: 'unconditional.final',
  },
];

const defaultFields = {
  jobId: '',
  invoices: '',
  waiverType: '',
  throughDate: '',
  customFile: 'false',
  requestNotarization: 'false',
  selectedFile: null,
  inputFile: '',
  projectNotFound: false,
  email: '',
  isPaid: false,
  recurringSchedule: 'false',
  recurringScheduleDay: '',
  requestWetInkSignature: 'false',
  unconditionalProgressTemplate: 'false',
  unconditionalProgressTemplateFile: '',
  paymentDetailType: '',
  notes: '',
  projectAddress: '',
};

const cleanLocalStorage = () => {
  setLocalStorageItem('fields', null, localStoragePrefix);
  setLocalStorageItem('project', null, localStoragePrefix);
  setLocalStorageItem('calendarDate', null, localStoragePrefix);
  setLocalStorageItem('paymentDate', null, localStoragePrefix);
  setLocalStorageItem('paymentMailedDate', null, localStoragePrefix);
};

const RequestWaiver = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const portalInfo = useSelector(selectPortalInfo);
  const [searchParams, setSearchParams] = useSearchParams();

  const isRestore = searchParams.get('restore') === 'true';

  const [calendarDate, setCalendarDate] = useState(() => {
    const saved = isRestore ? getLocalStorageItem('calendarDate', localStoragePrefix) : null;
    const savedObj = JSON.parse(saved);
    if (savedObj?.dateSelected) {
      savedObj.dateSelected = new Date(savedObj.dateSelected);
    }
    return savedObj || defaultCalendar;
  });

  const [recurringDate, setRecurringDate] = useState(() => {
    const saved = isRestore ? getLocalStorageItem('recurringDate', localStoragePrefix) : null;
    const savedObj = JSON.parse(saved);
    if (savedObj?.dateSelected) {
      savedObj.dateSelected = new Date(savedObj.dateSelected);
    }
    return savedObj || defaultCalendar;
  });

  const [fields, setFields] = useState(() => {
    const saved = isRestore ? getLocalStorageItem('fields', localStoragePrefix) : null;
    const savedObj = JSON.parse(saved);
    if (savedObj) {
      savedObj.inputFile = '';
    }
    return savedObj || defaultFields;
  });

  const [project, setProject] = useState(() => {
    const saved = isRestore ? getLocalStorageItem('project', localStoragePrefix) : null;
    const savedObj = JSON.parse(saved);
    return savedObj || null;
  });

  const {
    isWaiverPaymentEnabled,
    isWaiverWetInkEnabled,
    isWaiverRecurrenceEnabled,
    isWaiverTemplateEnabled,
    isWaiverUnconditionalTemplateEnabled,
    isWaiverPaymentDetailEnabled,
    isProjectCreationForWaiverRequestEnabled,
    countryList,
  } = portalInfo.settings || {};

  const [errors, setErrors] = useState({
    email: false,
  });

  const [projectInvoices, setProjectInvoices] = useState({});
  const [projectName, setProjectName] = useState('');
  const [options, setOptions] = useState([]);

  const isThroughDateEnabled = fields.waiverType.endsWith('progress') ? true : false;
  const isCustomFileEnabled = fields.customFile === 'true';
  const isRequestNotarization = fields.requestNotarization === 'true';
  const isRecurringSchedule = fields.recurringSchedule === 'true';
  const isRequestWetInkSignature = fields.requestWetInkSignature === 'true';
  const isUnconditionalProgressTemplateEnabled = fields.unconditionalProgressTemplate === 'true';

  const fetchProject = useMemo(
    () =>
      debounce((request, callback) => {
        const projects = getProjectsFilter({ search: request });
        callback(projects);
      }, 500),
    [],
  );

  useEffect(() => {
    if (isEmpty(user.email)) {
      return undefined;
    }

    setFields((prevState) => ({ ...prevState, email: user.email }));
  }, [user.email]);

  useEffect(() => {
    async function loadProjectInvoices() {
      const params = {
        projectId: project.id,
        type: fields.waiverType,
        // throughDate: endOfDay(parseISO(fields.throughDate || new Date().toISOString())), default to new date?
        throughDate: fields.throughDate ? moment(fields.throughDate).format('MM/DD/YYYY') : null,
      };
      const openProjectResult = (await getInvoicesToBeWaived(params).catch(console.error)) || {};
      openProjectResult.openProjectCount = openProjectResult.calculatedProps?.unpaidInvoiceCount || 0;
      openProjectResult.openProjectTotal = openProjectResult.calculatedProps?.openBalance || '';
      openProjectResult.exceptionsAmount = openProjectResult.calculatedProps?.exceptionsAmount || '';
      openProjectResult.exceptionsDate = openProjectResult.calculatedProps?.exceptionsDate || '';
      openProjectResult.invoices = openProjectResult.calculatedProps?.invoices;
      openProjectResult.throughDate = openProjectResult.calculatedProps?.throughDate || '';
      openProjectResult.invoiceIds = openProjectResult.invoicesToBeWaived?.map((i) => i.id).join(',');
      setProjectInvoices(openProjectResult);
      setFields((prevState) => ({ ...prevState, isPaid: !openProjectResult.openProjectCount }));
    }

    if (project?.id) {
      loadProjectInvoices();
    }
  }, [fields.throughDate, fields.waiverType, setProjectInvoices, project, setFields]);

  useEffect(() => {
    let active = true;

    !project &&
      fetchProject(projectName, async (results) => {
        const projects = await results;
        if (active) {
          let newOptions = [];

          if (projects) {
            newOptions = [...newOptions, ...projects.rows];
          }

          setOptions(newOptions);
        }
      });

    return () => {
      active = false;
    };
  }, [project, projectName, fetchProject]);

  useEffect(() => {
    setLocalStorageItem('fields', JSON.stringify(fields), localStoragePrefix);
    setLocalStorageItem('project', JSON.stringify(project), localStoragePrefix);
    setLocalStorageItem('calendarDate', JSON.stringify(calendarDate), localStoragePrefix);
    setLocalStorageItem('recurringDate', JSON.stringify(recurringDate), localStoragePrefix);
  }, [fields, project, calendarDate, recurringDate]);

  // load project info from URL
  useEffect(() => {
    const projectId = searchParams.get('projectId');

    async function fetchProjectUrl() {
      let project = options?.find((p) => p.id === parseInt(projectId)) || {};

      //Project not found, should search on api
      if (!size(project)) {
        const projects = await getProjectsFilter({ projectId });
        project = first(projects.rows);
        if (project) {
          setOptions([...options, project]);
        }
      }

      if (size(project)) {
        setProject(project);
        setLocalStorageItem('project', JSON.stringify(project), localStoragePrefix);
      }
    }

    if (projectId && size(options)) {
      fetchProjectUrl();
    }
  }, [searchParams, options, setProject]);

  const isUnconditionalProgress = fields.waiverType === 'unconditional.progress';

  const showPendingInvoices = fields.throughDate && projectInvoices?.openProjectCount && isWaiverPaymentEnabled;

  const showPaymentDetails = showPendingInvoices && isWaiverPaymentDetailEnabled && fields.isPaid;

  const endDate = fields.throughDate ? endOfDay(parseISO(fields.throughDate)).toISOString() : null;

  const payLink = `/pay?id=${projectInvoices?.invoiceIds}&requestWaiver=true`;

  const contentClass = `d-flex flex-grow-1 ${project ? 'hui-content-sidebar hui-content-sidebar-lg' : ''}`;

  const disableSubmit =
    errors.email || (isWaiverPaymentEnabled && isUnconditionalProgress && !isEmpty(projectInvoices) && !fields.isPaid);

  const showUnconditionalTemplateOption =
    fields.waiverType === 'conditional.progress' && isCustomFileEnabled && isWaiverUnconditionalTemplateEnabled;

  return (
    <div className={contentClass}>
      <form className="p-4 hui-history-content" onSubmit={handleSubmit}>
        <h1 className="text-start hui-title mb-4">Request Waiver</h1>

        <HuiPanel className="w-100 mb-4 mx-auto" boxShadow>
          <HuiPanelHeader title="Project Information" borderBottom>
            <div className="px-2">Please provide the project information.</div>
          </HuiPanelHeader>

          <HuiPanelContent className="p-4">
            <div className="row">
              <div className="col-12" hidden={fields.projectNotFound}>
                <HuiAutocomplete
                  label="Project"
                  id="projectName"
                  name="projectName"
                  options={options}
                  value={project}
                  getOptionLabel={getOptionLabelProject}
                  onChange={onChangeProject}
                  inputValue={projectName}
                  onInputChange={onInputChangeProject}
                  renderOption={renderOptionProject}
                  filterOptions={filterOptionsProject}
                  isOptionEqualToValue={isOptionEqualToValue}
                  placeholder="Type the project name,  job id or address to search the project"
                  size="small"
                  topLabel
                  required={!fields.projectNotFound}
                />
              </div>

              <div className="col-12">
                <HuiCheckbox
                  name="projectNotFound"
                  onChange={handleCheckbox}
                  size="small"
                  label="I can't find the project"
                  showLabel
                  checked={fields.projectNotFound}
                />
              </div>

              <div className="col-12" hidden={!fields.projectNotFound}>
                <HuiInput
                  multiline
                  maxRows={3}
                  minRows={3}
                  label="Invoices"
                  id="invoices"
                  name="invoices"
                  type="text"
                  value={fields.invoices}
                  onChange={handleChange}
                  size="large"
                  required={fields.projectNotFound}
                  topLabel
                  placeholder="Please provide at least one invoice number to assist us in finding the job."
                />
              </div>

              <div className="col-12" hidden={!fields.projectNotFound || !isProjectCreationForWaiverRequestEnabled}>
                <GoogleAddressForm
                  label="Project Address"
                  id="projectAddress"
                  name="projectAddress"
                  onChange={onChangeAddress}
                  required={fields.projectNotFound && isProjectCreationForWaiverRequestEnabled}
                  compacted
                  size="small"
                  countryList={countryList}
                  topLabel
                />
              </div>

              <div className="col-xl-6">
                <HuiSelect
                  topLabel
                  required
                  size="small"
                  value={fields.waiverType}
                  id="waiverType"
                  name="waiverType"
                  label="Waiver Type"
                  onChange={handleChange}
                  options={waiverTypeOptions}
                />
              </div>

              <div className="col-xl-6" hidden={!isThroughDateEnabled}>
                <HuiCalendar
                  onChange={(item) => {
                    setCalendarDate({ dateSelected: item, value: true });
                    setFields({ ...fields, throughDate: getDateInTimeZone(item) });
                  }}
                  className="mb-0 hui-calendar"
                  size="small"
                  label="Through Date"
                  id="throughDate"
                  name="throughDate"
                  topLabel
                  date={calendarDate}
                  required={isThroughDateEnabled}
                  maxDate={new Date()}
                  hideActions
                />
              </div>

              <div className="col-12" hidden={!showPendingInvoices}>
                <div className="border mb-3 p-4 rounded-3 hui-bg-yellow-light d-flex" hidden={!isUnconditionalProgress}>
                  <div className="d-flex">
                    <i className="hui-i hui-svg-toastr-warning"></i>
                  </div>

                  <div className="flex-grow-1 ms-2">
                    <strong>
                      There is {projectInvoices?.openProjectCount} unpaid invoice totaling{' '}
                      {currencyFormatter.format(projectInvoices?.openProjectTotal, { code: 'USD' })} - for the through
                      date {moment(fields.throughDate).format('M/DD/YYYY')} selected.
                    </strong>{' '}
                    <br />
                    We will be unable to process your request for an UNCONDITIONAL waiver until the invoice is paid.
                    <small>
                      If you need a waiver in order to get paid, change the type to a CONDITIONAL Progress above.
                    </small>
                    Please update the through date or make a payment to complete your{' '}
                    {toLower(startCase(fields.waiverType))} request.
                    <div className="mt-2">
                      <Link to={payLink} className="hui-btn hui-btn-yellow">
                        Click here to view invoice and make a payment
                      </Link>
                    </div>
                    <div className="mt-2">
                      <HuiCheckbox
                        name="isPaid"
                        onChange={handleCheckbox}
                        size="small"
                        label="Invoices already paid"
                        showLabel
                        checked={fields.isPaid}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {!!showPaymentDetails && (
                <RequestWaiverPaymentDetail
                  isRestore={isRestore}
                  fields={fields}
                  setFields={setFields}
                  handleChange={handleChange}
                />
              )}
              <div hidden={!isWaiverTemplateEnabled}>
                <div className="col-12 mb-3">
                  <HuiRadio
                    id="customFile"
                    name="customFile"
                    onChange={handleChange}
                    label="Does your GC require you to fill out a custom lien waiver form?"
                    row
                    required
                  >
                    <HuiRadioOption label="No" value="false" checked={fields.customFile === 'false'} />
                    <HuiRadioOption label="Yes" value="true" checked={fields.customFile === 'true'} />
                  </HuiRadio>
                </div>

                <div className="col-12 mb-3" hidden={!isCustomFileEnabled}>
                  <HuiFileUpload
                    className="hui-bg-gray-light border hui-radius p-3"
                    accept="application/pdf"
                    id="inputFile"
                    name="inputFile"
                    onChange={handleInputFile}
                    value={fields.inputFile}
                    required={isCustomFileEnabled}
                    label="Please upload a PDF copy of your custom waiver form.
                  Please note that custom waiver forms may take longer to execute due to the review process."
                    labelButton="Upload File"
                  />
                </div>
              </div>

              <div hidden={!showUnconditionalTemplateOption}>
                <div className="col-12 mb-3">
                  <HuiRadio
                    id="unconditionalProgressTemplate"
                    name="unconditionalProgressTemplate"
                    onChange={handleChange}
                    label="Would you like to provide the Unconditional Progress template now also?"
                    row
                    required={showUnconditionalTemplateOption}
                  >
                    <HuiRadioOption
                      label="No"
                      value="false"
                      checked={fields.unconditionalProgressTemplate === 'false'}
                    />
                    <HuiRadioOption
                      label="Yes"
                      value="true"
                      checked={fields.unconditionalProgressTemplate === 'true'}
                    />
                  </HuiRadio>
                </div>

                <div className="col-12 mb-3" hidden={!isUnconditionalProgressTemplateEnabled}>
                  <HuiFileUpload
                    className="hui-bg-gray-light border hui-radius p-3"
                    accept="application/pdf"
                    id="unconditionalProgressTemplateFile"
                    name="unconditionalProgressTemplateFile"
                    type="file"
                    onChange={handleInputFile}
                    value={fields.unconditionalProgressTemplateFile}
                    required={isUnconditionalProgressTemplateEnabled}
                    label="Please select your Unconditional Progress template."
                    labelButton="Upload File"
                  />
                </div>
              </div>

              <div className="col-12 mb-3">
                <HuiRadio
                  id="requestNotarization"
                  name="requestNotarization"
                  onChange={handleChange}
                  label="Do you need this waiver to be notarized?"
                  row
                  required
                >
                  <HuiRadioOption label="No" value="false" checked={fields.requestNotarization === 'false'} />
                  <HuiRadioOption label="Yes" value="true" checked={fields.requestNotarization === 'true'} />
                </HuiRadio>
              </div>

              <div className="col-12 mb-3" hidden={!isRequestNotarization}>
                <div className="hui-bg-yellow-light rounded-3 p-2 d-flex align-items-center">
                  <div className="d-flex me-2">
                    <i className="hui-i hui-svg-warning-sm"></i>
                  </div>
                  <div className="flex-grow-1">
                    The notarization process may add to the time it takes to send you the waiver. <br />
                    Only select this option if you need it.
                  </div>
                </div>
              </div>

              <div className="col-12 mb-3" hidden={!isWaiverWetInkEnabled}>
                <HuiRadio
                  id="requestWetInkSignature"
                  name="requestWetInkSignature"
                  onChange={handleChange}
                  label="Do you require a wet ink signature?"
                  row
                  required={isWaiverWetInkEnabled}
                >
                  <HuiRadioOption label="No" value="false" checked={fields.requestWetInkSignature === 'false'} />
                  <HuiRadioOption label="Yes" value="true" checked={fields.requestWetInkSignature === 'true'} />
                </HuiRadio>
              </div>

              <div className="col-12 mb-3" hidden={!isRequestWetInkSignature}>
                <div className="hui-bg-yellow-light rounded-3 p-2 d-flex align-items-center">
                  <div className="d-flex me-2">
                    <i className="hui-i hui-svg-warning-sm"></i>
                  </div>
                  <div className="flex-grow-1">
                    A wet ink signature will create substantial delays. A wet ink signature is a hand signed document
                    that is mailed to you.
                  </div>
                </div>
              </div>

              <div className="col-12 mb-3" hidden={!isWaiverRecurrenceEnabled}>
                <HuiRadio
                  id="recurringSchedule"
                  className="mb-0"
                  name="recurringSchedule"
                  onChange={handleChange}
                  label="Would you like to setup a reoccurring monthly waiver schedule?"
                  row
                  required={isWaiverRecurrenceEnabled}
                >
                  <HuiRadioOption label="No" value="false" checked={fields.recurringSchedule === 'false'} />
                  <HuiRadioOption label="Yes" value="true" checked={fields.recurringSchedule === 'true'} />
                </HuiRadio>
              </div>

              <div className="col-3" hidden={!isRecurringSchedule}>
                <HuiCalendar
                  onChange={(item) => {
                    setRecurringDate({ dateSelected: item, value: true });
                    setFields({ ...fields, recurringScheduleDay: item.getDate() });
                  }}
                  className="mb-0 hui-calendar"
                  size="small"
                  label="On what day of month?"
                  id="recurringScheduleDay"
                  name="recurringScheduleDay"
                  topLabel
                  date={recurringDate}
                  required={isRecurringSchedule}
                  hideActions
                  onlyDayPicker
                  hidePassiveDays
                />
              </div>

              <div className="col-12">
                <HuiInput
                  multiline
                  maxRows={3}
                  minRows={3}
                  label="Notes"
                  id="notes"
                  name="notes"
                  type="text"
                  value={fields.notes}
                  onChange={handleChange}
                  size="large"
                  topLabel
                />
              </div>
            </div>
          </HuiPanelContent>

          <HuiPanelHeader title="Contact Information" borderBottom>
            <div className="px-2">Please provide your information.</div>
          </HuiPanelHeader>

          <HuiPanelContent className="p-4">
            <div className="col-12">
              <HuiInput
                label="Your Email (to have completed waiver form sent to, comma separated)"
                id="email"
                name="email"
                error={errors.email}
                value={fields.email}
                onChange={handleChange}
                onBlur={handleEmailBlur}
                topLabel
                required
              />
            </div>
          </HuiPanelContent>

          <HuiPanelActions>
            <div className="col-12 d-flex flex-column">
              <button type="submit" className="hui-btn hui-btn-xl hui-btn-green" disabled={disableSubmit}>
                Submit Request
              </button>
            </div>
          </HuiPanelActions>
        </HuiPanel>
      </form>

      <div
        className="hui-sidebar-panel hui-sidebar-panel-lg hui-sidebar-fixed hui-bg-white"
        role="presentation"
        hidden={!project}
      >
        <SidebarHeader closeSidebar={closeSidebar}>
          <div className="flex-grow-1">
            <h2 className="hui-title d-flex align-items-center mb-0">Project Details</h2>
          </div>
        </SidebarHeader>

        {project && (
          <div className="hui-bg-gray-light p-4 d-flex flex-column">
            <h2 className="hui-subtitle mb-2">Waiver History</h2>

            <HuiCard boxShadow border className="p-0 mb-4">
              <HuiCardContent className="p-0">
                <HistoryWaivers
                  className="hui-table hui-table-fluid"
                  hideHeader
                  project={project}
                  isPaginated={false}
                  isSortable={false}
                  hideProjectColumns
                />
              </HuiCardContent>
            </HuiCard>

            <h2 className="hui-subtitle mb-2">Invoice History</h2>

            <HuiCard boxShadow border className="p-0 mb-4">
              <HuiCardContent className="p-0">
                <HistoryInvoices
                  className="hui-table hui-table-fluid"
                  hideHeader
                  project={project}
                  invoices={projectInvoices.invoices}
                  endDate={endDate}
                  isPaginated={false}
                  isSortable={false}
                  hideProjectColumns
                />
              </HuiCardContent>
            </HuiCard>
          </div>
        )}
      </div>
    </div>
  );

  function closeSidebar(keepOptions = false) {
    removeProjectParams();
    setProject(null);
    if (!keepOptions) {
      setOptions([]);
    }
    setProjectName('');
  }

  function removeProjectParams() {
    searchParams.delete('projectId');
    setSearchParams(searchParams);
  }

  function handleChange(e) {
    if (e.target.name === 'waiverType' && !e.target.value.endsWith('progress')) {
      setFields({ ...fields, [e.target.name]: e.target.value, throughDate: null });
      setCalendarDate(defaultCalendar);
    } else if (e.target.name === 'customFile' && e.target.value === 'false') {
      setFields({ ...fields, [e.target.name]: e.target.value, uploaded: false, inputFile: '', files: [] });
    } else if (e.target.name === 'recurringSchedule' && e.target.value === 'false') {
      setFields({ ...fields, [e.target.name]: e.target.value, recurringScheduleDay: null });
      setRecurringDate(defaultCalendar);
    } else if (e.target.name === 'paymentDetailType') {
      setFields({ ...fields, [e.target.name]: e.target.value, ...getPaymentFieldsToClean(e.target.value) });
    } else {
      setFields({ ...fields, [e.target.name]: removeExtraSpaces(e.target.value) });
    }
  }

  function getPaymentFieldsToClean(detailType) {
    let fields = {};

    if (!detailType) {
      fields = { paymentDetailType: null };
    }

    if (!detailType || detailType === 'branch') {
      fields = {
        ...fields,
        paymentMailedDate: null,
        paymentCheckNumber: null,
        paymentCheckAmount: null,
      };
    }

    if (!detailType || detailType === 'mailed') {
      fields = {
        ...fields,
        paymentBranchLocation: null,
        paymentDate: null,
        paymentMethod: null,
      };
    }
    return fields;
  }

  function handleCheckbox(e) {
    if (e.target.name === 'projectNotFound' && e.target.checked) {
      setFields({ ...fields, jobId: null, jobName: null });
      closeSidebar(true);
    }
    if (e.target.name === 'isPaid') {
      setFields({ ...fields, [e.target.name]: e.target.checked, isInvoiceAlreadyPaid: e.target.checked, ...getPaymentFieldsToClean(null) });
    } else {
      setFields({ ...fields, [e.target.name]: e.target.checked });
    }
  }

  function handleInputFile(e) {
    setFields({ ...fields, [e.target.name]: e.target.value, uploaded: true, files: e.target.files });
  }

  function onChangeAddress(event) {
    const { target: { value, name } = {} } = event;
    const { formattedAddress } = value;
    handleChange({
      target: {
        name,
        value: formattedAddress,
      },
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    dispatch(openLoading());
    try {
      const payload = buildPayload(fields, project);
      await createWaiverRequest(payload);
      cleanLocalStorage();
      navigate('/request-waiver-confirmation');
    } catch (error) {
      toastr.error(error.message, 'Error submitting request');
      console.error(error);
    }
    dispatch(closeLoading());
    return false;
  }

  function buildPayload(fields, project) {
    const formData = {
      ...fields,

      name: user.name,
      projectId: project?.id,
      OrganizationId: project?.OrganizationId ? project?.OrganizationId : portalInfo.organization.id,
      source: 'portal',
    };

    return formData;
  }

  function renderOptionProject(props, option) {
    const { jobId, jobName, address } = option;
    const noIdentifiers = !jobId && !jobName;

    return (
      <li {...props} key={option.id}>
        <Grid container alignItems="center">
          <Grid item xs>
            {noIdentifiers ? (
              option.address
            ) : (
              <>
                {option.jobName ? `Job Name: ${option.jobName} - ` : ''}
                {option.jobId ? `Job Id: ${option.jobId}` : ''}
              </>
            )}
            <Typography variant="body2" color="text.secondary" hidden={noIdentifiers}>
              {option.address}
            </Typography>
          </Grid>
        </Grid>
      </li>
    );
  }

  function onChangeProject(event, newValue) {
    setProject(newValue);
    const { jobId, jobName } = newValue || {};
    setFields({ ...fields, jobId, jobName });
    removeProjectParams();
    if (newValue === null) {
      closeSidebar();
    }
  }

  function onInputChangeProject(event, newInputValue) {
    setProjectName(newInputValue);
  }

  function filterOptionsProject(x) {
    return x;
  }

  function getOptionLabelProject(option) {
    const projectNameLabel = option?.jobName || option?.jobId || option?.address;

    return projectNameLabel || '';
  }

  function isOptionEqualToValue(option, value) {
    return option.id === value.id;
  }

  function handleEmailBlur(e) {
    const email = e.target.value;
    const arrayEmails = email.split(',').map(function (e) {
      return e.trim();
    });

    const pattern = /^([+\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

    const validateEmailByString = (e) => {
      const emailId = e.replace(/(\r\n|\n|\r| )/gm, '');
      return pattern.test(emailId);
    };

    const isAllEmailValid = arrayEmails.every(validateEmailByString);

    if (isAllEmailValid) {
      setFields({ ...fields, [e.target.name]: arrayEmails });
    }
    setErrors({ ...errors, [e.target.name]: !isAllEmailValid });
  }
};

export default RequestWaiver;
