import { isString } from 'lodash';
import * as React from 'react';

export const getUtilsTextColorByStatus = {
  SHORT_PAY: 'hui-color-yellow',
  OVER_PAY: 'hui-color-green',
  COMPLETE_PAY: 'hui-color-green',
  REFUNDED: 'hui-color-yellow',
  CANCELED: 'hui-color-red',
};

export const getUtilsTextColorByPaymentStatus = {
  STARTED: 'hui-color-yellow',
  PENDING: 'hui-color-green',
  APPROVED: 'hui-color-green',
  CANCELED: 'hui-color-red',
  REFUNDED: 'hui-color-red',
  CHARGEBACK: 'hui-color-red',
  VOIDED: 'hui-color-red',
  SCHEDULED: 'hui-color-yellow',
};

export function invoicePaidPercentage(amount, balance, text) {
  const totalPercentage = parseFloat(((100 * (amount - balance)) / amount).toFixed(0));

  const colorNotPaid = totalPercentage === 0 ? 'hui-color-red' : '';
  const colorPaymentProgress = totalPercentage >= 1 && totalPercentage <= 99 ? 'hui-color-yellow' : '';
  const colorIsPaid = totalPercentage >= 100 ? 'hui-color-green' : '';

  return (
    <strong className={`${colorNotPaid} ${colorIsPaid} ${colorPaymentProgress}`}>{`${totalPercentage} ${text}`}</strong>
  );
}

export function removeExtraSpaces(text) {
  if (isString(text)) {
    return String(text || '').replace(/\s+/g, ' ').trimStart();
  }
  return text;
};
