import Bugsnag from '@bugsnag/js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import toastr from 'toastr';
import App from './App';
import { store } from './store/store';
import { AppTheme } from './styles/appTheme';
import { toastrOptions } from './styles/toastrOptions';
// Import Fonts
import './styles/index.fonts';
// Import Styles
import './styles/index.styles';
import { getBugsnagErrorBoundary, initLoggers } from './utils/utilsLoggers';

const theme = createTheme(AppTheme);

initLoggers();

toastr.options = {
  ...toastrOptions,
};

const ErrorBoundary = getBugsnagErrorBoundary();

const RootApp = () => (
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </ErrorBoundary>
);

const renderRoot = () => {
  ReactDOM.createRoot(document.getElementById('root')).render(<RootApp />);
};

renderRoot();

export default renderRoot;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
