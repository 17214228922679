import Bugsnag from '@bugsnag/js';
import { identifyOpenReplay } from '../../utils/utilsLoggers';

export function identifyUser(user, portal) {
  if (!user && !portal) {
    return;
  }
  const { id: userId, name, email, organizationId: orgId } = user || {};
  const { id: portalId, domain, organization: { id: portalOrgId } = {} } = portal || {};

  const portalData = {
    portalId,
    portalDomain: domain,
    portalOrgId,
  };

  const userData = {
    name,
    email,
    orgId: orgId,
    ...portalData,
  };

  Bugsnag.addMetadata('portal', portalData);
  Bugsnag.setUser(userId, email, name);
  identifyOpenReplay(userData);
}
