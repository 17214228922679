import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import OpenReplay, { SanitizeLevel } from '@openreplay/tracker';
import { isEmpty } from 'lodash';
import React from 'react';
import { firebase } from '../actions/auth/firebase';

export const FIELDS_TO_REDACT = ['cvv', 'token', 'account', 'expiry', 'password', 'last4'];

export let openReplayTracker;

export function initLoggers() {
  const appEnv = process.env.REACT_APP_ENV;

  if (appEnv !== 'local') {
    //Initialize Open Replay
    initializeOpenReplay();
    // Initialize analytics
    initializeFirebase();
  }

  // Initialize bugsnag
  initializeBugsnag(appEnv, getSessionURL());
}

function initializeOpenReplay() {
  openReplayTracker = new OpenReplay({
    projectKey: 'xRW5goKesZVcJwNI6oVc',
    ingestPoint: 'https://openreplay.handle.com/ingest',
    captureIFrames: false,
    defaultInputMode: 0,
    obscureTextEmails: false,
    obscureTextNumbers: false,
    obscureInputEmails: false,
    obscureInputDates: false,
    obscureInputNumbers: false,
    domSanitizer: (node) => {
      if (node.hasAttribute('data-private')) {
        return SanitizeLevel.Obscured;
      }

      return SanitizeLevel.Plain;
    },
    network: {
      ignoreHeaders: ['authorization'],
      capturePayload: true,
      sanitizer: (data) => {
        if (data.method === 'POST') {
          data.request = sanitizeRequest(data.request, FIELDS_TO_REDACT);
          data.response = sanitizeRequest(data.response, FIELDS_TO_REDACT);
        }
        return data;
      },
    },
  });
  openReplayTracker.start();
}

export function identifyOpenReplay(userData) {
  if (openReplayTracker) {
    openReplayTracker.setUserID(userData?.email);
    openReplayTracker.setMetadata(userData);
  }
}

function getSessionURL() {
  return openReplayTracker?.getSessionURL({ withCurrentTime: true });
}

function initializeFirebase() {
  firebase.analytics().setAnalyticsCollectionEnabled(true);
}

function initializeBugsnag(appEnv, sessionUrl) {
  // Initialize bugsnag
  Bugsnag.start({
    releaseStage: appEnv,
    enabledReleaseStages: ['production', 'development'],
    apiKey: 'c03aeb0464a9c2d39cebbbf7aa49bdfb',
    plugins: [new BugsnagPluginReact()],
    redactedKeys: FIELDS_TO_REDACT,
  });

  Bugsnag.beforeNotify = function (data) {
    data.metaData.sessionURL = sessionUrl;
    return data;
  };
}

export function getBugsnagErrorBoundary() {
  const bugsnagPlugin = Bugsnag.getPlugin('react');
  const errorBoundary = bugsnagPlugin ? bugsnagPlugin.createErrorBoundary(React) : ({ children }) => children; // Fallback that renders children directly
  return errorBoundary;
}

export function sanitizeRequest(request, fieldsToRemove) {
  if (!request || !fieldsToRemove) {
    return request;
  }

  try {
    if (!isEmpty(request.body)) {
      let bodyJson = null;

      if (typeof request.body === 'string' && isValidJSON(request.body)) {
        bodyJson = JSON.parse(request.body);
      } else if (typeof request.body === 'object') {
        bodyJson = request.body;
      }

      if (bodyJson) {
        const redactedObj = redactJson(bodyJson, fieldsToRemove);
        request.body = JSON.stringify(redactedObj);
      }
    }
  } catch (error) {
    console.log(error);
  }

  return request;
}

function isValidJSON(data) {
  try {
    JSON.parse(data);
    return true;
  } catch (error) {
    return false;
  }
}

export function redactJson(obj, fields) {
  if (!obj) {
    return null;
  }

  for (let field of fields) {
    if (Object.keys(obj).includes(field)) {
      obj[field] = '*REDACTED*';
    }
  }

  return obj;
}
